<template>
  <v-container fluid>
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-tabs
        v-model="tab"
        @change="tabChanged(tab)"
        background-color="#5E2FBE"
        dark
        disabled
      >
        <v-tab
          :disabled="loading"
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="my-auto ml-auto mr-2 rounded-0 white"
          @click="() => createPromotion()"
        >
          <span class="primary--text">
            <v-icon>mdi-plus</v-icon>&nbsp;{{
              this.$t("promotions.create_button")
            }}
          </span>
        </v-btn>
        <v-btn
          small
          class="my-auto ml-auto mr-2 rounded-0 white"
          @click="() => showImportPromotionsModal()"
        >
          <span class="primary--text">
            <v-icon>mdi-plus</v-icon>&nbsp;{{
              this.$t("promotions.import_button")
            }}
          </span>
        </v-btn>
        <import-promotions-modal
          :currentTab="items[tab]"
        ></import-promotions-modal>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <active-tab v-if="item.content == 'active'"></active-tab>
          <done-tab v-if="item.content == 'done'"></done-tab>
          <canceled-tab v-if="item.content == 'canceled'"></canceled-tab>
          <draft-tab v-if="item.content == 'draft'"></draft-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    ActiveTab: () => import("./tabs/ActivePromotionsTab.vue"),
    DoneTab: () => import("./tabs/DonePromotionsTab.vue"),
    CanceledTab: () => import("./tabs/CanceledPromotionsTab.vue"),
    DraftTab: () => import("./tabs/DraftPromotionsTab.vue"),
    ImportPromotionsModal: () => import("./modals/ImportPromotionsModal.vue"),
  },

  methods: {
    async tabChanged(index) {
      try {
        this.loading = true;
        this.$store.commit("promotions/CLEAR_LIST");
        await this.$store.dispatch("promotions/list", {
          status: this.items[index].status,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    createPromotion() {
      this.$store.dispatch("promotions/initialize");
      this.$router.push("/promotions/create");
    },
    showImportPromotionsModal() {
      this.$store.commit(
        "promotions/SET_IMPORT_PROMOTIONS_MODAL_VISIBLE",
        true
      );
    },
  },

  data() {
    return {
      tab: null,
      loading: false,
      items: [
        {
          tab: this.$t("active"),
          content: "active",
          status: "active",
        },
        {
          tab: this.$t("done"),
          content: "done",
          status: "done",
        },
        {
          tab: this.$t("canceled"),
          content: "canceled",
          status: "canceled",
        },
        {
          tab: this.$t("draft"),
          content: "draft",
          status: "draft",
        },
      ],
    };
  },
};
</script>